<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">
            404
          </h1>
          <h4 class="pt-3">
            ¡Vaya! Estás perdido.
          </h4>
          <p class="text-muted">
            La página que estás buscando, no ha sido encontrada.
          </p>
          <CButton 
            class="btn btn-primary"
            @click="click" 
          >
            Inicio
          </CButton>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";

async function checkToken(token) {
  await axios.get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_CHECKTOKEN, {
      headers: {
        Authorization: token,
      },
    }).catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location.href = "#/pages/login";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
}

export default {
  name: "Page404",
  created() {
    checkToken(localStorage.token);
  },
  methods:{
    click(){
      window.location="#/Inicio"
    }
  }
};
</script>
